import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: ()=> import('@/views/login/login.vue'),
    meta:{title:'高标准农田全流程数字化管理系统'}
  },
  {
    path: '/index',
    name: 'index',
    component: ()=> import('@/views/index/index.vue'),
    meta:{title:'高标准农田建设监管系统'}
  },
  
  {
    path: '/gis',
    name: 'gis',
    component: ()=> import('@/views/gis/gisHome.vue'),
    meta:{title:'卫星遥感图像'}
  },
  {
    path: '/itemAllLook',
    name: 'itemAllLook',
    component: ()=> import('@/views/itemAllLook/itemHome.vue'),
    meta:{title:'高标农田项目总览'}
  },
  {
    path: '/fundAllLook',
    name: 'fundAllLook',
    component: ()=> import('@/views/fundAllLook/fundHome.vue'),
    meta:{title:'高标农田资金总览'}
  },
  {
    path: '/farmingFourMsg',
    name: 'farmingFourMsg',
    component: ()=> import('@/views/farmingFourMsg/farmingHome.vue'),
    meta:{title:'智慧农业'}
  },
  {
    path: '/knowledgeBase',
    name: 'knowledgeBase',
    component: ()=> import('@/views/knowledgeBase/knowledge_base.vue'),
    meta:{title:'高标准农田知识库'}
  },
  {
    path:"/",
    redirect:"/login"
  }
  
]

const router = new VueRouter({
  routes
})

export default router
