import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.config.productionTip = false

import '@/assets/font/iconfont.css'
import '@/assets/font/font.css'


import scroll from 'vue-seamless-scroll'
Vue.use(scroll);

// 在main.js中全局引入
import VideoPlayer from 'vue-video-player/src';
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'

Vue.use(VideoPlayer)
// import vPlayBack from 'v-playback'
// Vue.use(vPlayBack)

//全局引入css动画库
import 'animate.css';

//引入websocket
// const ws = new WebSocket('ws://192.168.1.87:8282');//配置服务器地址
// Vue.prototype.$ws = ws;


//rem.js等比适配文件
import './utils/rem'

//防抖函数
import { debounce } from "./utils/tool"
Vue.prototype.$debounce = debounce
 

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
