 /* ===========防抖函数============= */
export function debounce(func, wait=1000) {
  let timer = null;
  return function(...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
      timer = undefined;
    }, wait);
  };
}

// export function debounce(func, wait=1000){
//     let timeout = null;
//     return function(){
//         if(timeout !== null) clearTimeout(timeout);
//         timeout = setTimeout(func,wait)
//     }
// }
